<template>
  <v-app>
    <div v-if="currentUser.id !== null && showNavBar">
      <NavBar @click="changeDrawer"/>
    </div>
    <v-main>
       <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <div v-for="(route,i) in routes" :key="i">
              <v-list-item @click="$router.push(route.route)">
                <v-list-item-icon>
                  <v-icon>{{route.icon}}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{route.name}}</v-list-item-title>
              </v-list-item>
          </div>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
  import NavBar from '@/components/shared/NavBar';
  import {mapActions, mapGetters} from 'vuex';
export default {
  name: 'App',
  components: {
    NavBar
  },
  mounted(){
    // console.log('user: ', this.GET_USER().type);
  },
  computed: {
    currentUser(){
      return this.GET_USER()
    },
    showNavBar(){
      if(!!this.$route.meta && !!this.$route.meta.roles) {
        return this.$route.meta.roles.includes('admin');
      } 

      return false;
      
    }
  },
  methods: {
    ...mapActions("User", ["LOGOUT"]),
    logout(){
      this.LOGOUT()
    },
    changeDrawer(){
      this.drawer = true;
    }
  },
  data: () => ({
    ...mapGetters('User', ['GET_USER']),
    drawer: false,
    routes: [
      // {
      //   icon: 'mdi-home', route: '/initialPage',name: "Página inicial"
      // },
      {
        icon: 'mdi-account', route: '/users', name: "Usuarios",
      },
      {
        icon: 'mdi-play', route: '/classItems',name: "Aulas"
      },
      {
        icon: 'mdi-currency-usd', route: '/listPlans',name: "Planos"
      },
      {
        icon: 'mdi-arrow-up', route: '/levels',name: "Níveis das aulas"
      },
      {
        icon: 'mdi-clipboard-multiple', route: '/categories',name: "Categorias"
      },
      {
        icon: 'mdi-ticket-percent', route: '/coupons',name: "Cupons"
      },
      {
        icon: 'mdi-ticket-percent', route: '/report-coupons',name: "Relatório de cupons"
      },
      {
        icon: 'mdi-chart-bar', route: '/subscription-report',name: "Relatório de Assinaturas"
      },
      {
        icon: 'mdi-webhook', route: '/actions-report',name: "Relatório de Ações"
      },
      {
        icon: 'mdi-cog-outline', route: '/configs',name: "Configurações"
      }
    ]
  }),
};
</script>
