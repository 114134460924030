<template>
  <div>
    <h2 style="margin: 10px 20px">Relatório de conciliação de pagamentos</h2>
    <div style="width: 100%; padding: 20px">
      <v-col class="filter" cols="12">
        <v-form>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field v-model="dataIni" label="Data Inicial" type="date"
                prepend-icon="mdi-calendar"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model="dataFin" label="Data Final" type="date" prepend-icon="mdi-calendar"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select v-model="originFilter" :items="originFilterOption" item-text="title"
                prepend-icon="mdi-cart-arrow-down" item-value="id" label="Origem" single-line></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select v-model="planFilter" :items="planFilterOption" item-text="title" item-value="id" label="Plano"
                prepend-icon="mdi-briefcase-check"></v-select>
            </v-col>
          </v-row>
        </v-form>
        <v-row align="end" justify="end">
          <v-btn color="error" class="btn-margin" :loading="loading" depressed @click="removeFilters()"
            :disabled="disabledButton">
            Remover filtros
          </v-btn>
          <v-btn color="primary" :loading="loading" class="btn-margin" depressed :disabled="disabledButton"
            @click="loadFilters()">
            Filtrar
          </v-btn>
        </v-row>
      </v-col>
      <v-container fluid class="pa-0">
        <v-row class="fill-height" justify="center" align="center">
          <v-col cols="12">
            <v-card elevation="2" shaped class="pa-5 fill-height border renovation-card">
              <v-card-title> {{ isFuture ? "Previsão de assinaturas" : "Renovações e Cancelamentos por dia"
                }}</v-card-title>
              <v-card-text>
                <apexchart type="line" height="200" :options="yearlyChartOptions" :series="salesByCurrentYear" />
                <p class="renovations-total" v-if="!isFuture">
                  Total de Renovações: {{ daily_total }} -
                  {{ daily_total_value }}
                </p>
                <p class="renovations-total" v-if="!isFuture">
                  Total de Novas Assinaturas: {{ daily_new_subscriptions }} -
                  {{ daily_new_subscriptions_value }}
                </p>
                <p class="renovations-total" v-if="!isFuture">
                  Total de Cancelamentos: {{ daily_cancelation }} -
                  {{ daily_cancelation_value }}
                </p>
                <p class="renovations-total" v-if="isFuture || isFuturePartial">
                  Assinaturas futuras: {{ future_subscriptions }} -
                  {{ future_value }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="!hideFilters" justify="space-between" class="mt-5">
          <v-col cols="12" sm="6" md="3">
            <v-card elevation="2" class="pa-3 card">
              <pie-chart-subscription v-if="pieChartAll?.list.length > 0" :title="'Total Assinaturas'"
                :data="pieChartAll"></pie-chart-subscription>
              <span class="card-total">
                Total: {{ pieChartAll.total }} -
                {{ formatCurrency(pieChartAll.total_sum) }}</span>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-card elevation="2" class="pa-3 card">
              <pie-chart-subscription v-if="pieChartAsaas?.list.length > 0" :title="'Assinaturas Asaas'"
                :data="pieChartAsaas"></pie-chart-subscription>
              <span class="card-total">
                Total: {{ pieChartAsaas.total }} -
                {{ formatCurrency(pieChartAsaas.total_sum) }}</span>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-card elevation="2" class="pa-3 card">
              <pie-chart-subscription v-if="pieChartGoogle?.list.length > 0" :title="'Assinaturas Google'"
                :data="pieChartGoogle"></pie-chart-subscription>
              <span class="card-total">
                Total: {{ pieChartGoogle.total }} -
                {{ formatCurrency(pieChartGoogle.total_sum) }}</span>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-card elevation="2" class="pa-3 card">
              <pie-chart-subscription v-if="pieChartApple?.list.length > 0" :title="'Assinaturas Apple'"
                :data="pieChartApple"></pie-chart-subscription>

              <span class="card-total">
                Total: {{ pieChartApple.total }} -
                {{ formatCurrency(pieChartApple.total_sum) }}</span>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line hide-details
              style="margin-top: 10px; margin-bottom: 10px">
            </v-text-field>
            <v-data-table :loading="loading" :headers="headers" :items="results" :items-per-page="10" :search="search"
              class="elevation-1">
              <template v-slot:item.plan="{ item }">
                <div :style="{
                  padding: '10px',
                  color: 'white',
                  backgroundColor: getPlan(item.plan),
                  borderRadius: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                }">
                  {{ item.plan }}
                </div>
              </template>

              <template v-slot:item.value="{ item }">
                {{ formatCurrency(item.value) }}
                <!-- {{ computedOrigin(item.payment_type) }} -->
              </template>

              <template v-slot:item.payment_type="{ item }">
                {{ getOrigin(item.payment_type) }}
                <!-- {{ computedOrigin(item.payment_type) }} -->
              </template>

              <template v-slot:footer>
                <div class="footer-table">
                  <td class="custom-td title-footer">Total:</td>
                  <td class="custom-td">{{ total }}</td>
                  <td class="custom-td">{{ formatCurrency(total_value) }}</td>
                  <td class="custom-td"></td>
                  <td class="custom-td"></td>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<style>
.custom-table th,
.custom-table td {
  padding: 0 120px;
  /* Define o preenchimento personalizado */
}

.footer-table {
  border-top: thin solid rgba(0, 0, 0, 0.12);
  font-size: 0.875rem;
  height: 48px;
  display: flex;
  padding: 10px 15px;

  .custom-td {
    width: 20%;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .title-footer {
    font-weight: bold;
  }
}

.filter {
  margin-bottom: 15px;
}

.btn-margin {
  margin: 5px;
}

.border {
  border: 1px solid #848484 !important;
}

.card {
  position: relative;
  border: 1px solid #848484 !important;
  height: 380px !important;
}

.renovations-total {
  font-weight: bold;
  font-size: 16px;
}

.renovation-card {
  text-align: center;
}

.card-total {
  position: absolute;
  bottom: 10px;
  left: 25%;
  font-weight: bold;
}

.cursor {
  cursor: pointer;
}

.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
<script>
import VueApexCharts from "vue-apexcharts";
import PieChartSubscription from "./PieChartSubscription.vue";
import moment from "moment";
moment.locale("pt-br");

export default {
  components: {
    apexchart: VueApexCharts,
    PieChartSubscription,
  },
  methods: {

    async loadFilters() {
      this.getSubscriptionsFilter();
      this.getSubscriptionsPieFilter(""); // Pie chart all
      this.getSubscriptionsPieFilter("ASAAS");
      this.getSubscriptionsPieFilter("PLAY_STORE");
      this.getSubscriptionsPieFilter("APP_STORE");

      const today = new Date();
      today.setHours(0, 0, 0, 0); // Remove a hora para comparar apenas a data

      const inputDate = new Date(this.dataIni);
      inputDate.setHours(0, 0, 0, 0); // Remove a hora para evitar problemas de comparação

      if (inputDate > today) {
        this.getFutureSubscriptions();

        if (!this.isFuture) {
          this.toggleFutureDate();
          this.toggleHideFilters();
        }
      } else {
        this.getRenovations();

        if (this.isFuture) {
          this.toggleFutureDate();
          this.toggleHideFilters();
        }
      }
    },
    toggleFutureDate() {
      this.isFuture = !this.isFuture;
    },
    toggleHideFilters() {
      this.hideFilters = !this.hideFilters;
    },
    async removeFilters() {
      this.dataIni = moment().startOf("month").format("YYYY-MM-DD");
      this.dataFin = moment().format("YYYY-MM-DD");
      this.originFilter = "";
      this.planFilter = "";
    },
    async getSubscriptionsFilter() {
      this.loading = true;
      this.disabledButton = true;
      const url = `/subscriptions_report?start_date=${this.dataIni}&end_date=${this.dataFin}&payment_plan_id=${this.planFilter}&payment_type=${this.originFilter}`;
      const resp = await this.$api.get(url);

      this.results = resp.data.list;
      this.total = resp.data.total;
      this.total_value = resp.data.total_value;
      this.loading = false;
      this.disabledButton = false;
      return true;
    },
    async getSubscriptionsPieFilter(type) {
      const url = `/subscriptions_pie_report?start_date=${this.dataIni}&end_date=${this.dataFin}&payment_type=${type}`;
      const resp = await this.$api.get(url);
      switch (type) {
        case "APP_STORE":
          this.pieChartApple = resp.data;
          break;
        case "PLAY_STORE":
          this.pieChartGoogle = resp.data;
          break;
        case "ASAAS":
          this.pieChartAsaas = resp.data;
          break;
        default:
          this.pieChartAll = resp.data;
      }
    },
    async getPlanList() {
      const resp = await this.$api.get("/subscription_plans");
      this.planFilterOption = resp.data;
    },
    formatLineChartDates(renovations, cancelations, new_subs, future_subs = []) {
      const mergedSet = new Set([...renovations, ...cancelations, ...new_subs, ...future_subs]);

      return Array.from(mergedSet).sort((a, b) => {
        const [dayA, monthA, yearA] = a.split("/").map(Number);
        const [dayB, monthB, yearB] = b.split("/").map(Number);

        const dateA = new Date(yearA, monthA - 1, dayA);
        const dateB = new Date(yearB, monthB - 1, dayB);

        return dateA.getTime() - dateB.getTime();
      });
    },
    async getFutureSubscriptions() {
      const url = `/future_subscriptions?initial_date=${this.dataIni}&final_date=${this.dataFin}`;
      const resp = await this.$api.get(url);
      this.future_subscriptions = resp.data.total_new_sub;
      this.future_value = resp.data.total_value;

      this.yearlyChartOptions = {
        chart: {
          type: "line",
          toolbar: {
            show: true,
          },
        },
        series: [
          {
            name: "Previsão",
            data: resp.data.values,
          },
        ],
        xaxis: {
          categories: resp.data.dates, // ['05/2023', '06/2023']
          title: {
            text: "Dias",
          },
          labels: {
            style: {
              fontSize: "9px",
            },
          },
        },
        yaxis: {
          title: {
            text: "Total",
          },
        },
      }

    },
    async getRenovations() {
      const url = `/renewals_per_day?start_date=${this.dataIni}&end_date=${this.dataFin}`;
      const url2 = `/cancelations_per_day?start_date=${this.dataIni}&end_date=${this.dataFin}`;
      const url3 = `/new_subscriptions_per_day?start_date=${this.dataIni}&end_date=${this.dataFin}`;

      let future_subs = [];
      let future_values = null;

      if (this.isPartialFuture()) {
        const url4 = `/future_subscriptions?initial_date=${this.dataIni}&final_date=${this.dataFin}`;
        const resp4 = await this.$api.get(url4);
        this.future_subscriptions = resp4.data.total_new_sub;
        this.future_value = resp4.data.total_value;
        future_subs = resp4.data.dates
        future_values = resp4.data.values;
        this.isFuturePartial = true;
      }

      const [resp, resp2, resp3] = await Promise.all([
        this.$api.get(url),
        this.$api.get(url2),
        this.$api.get(url3),
      ])

      this.daily_total = resp.data.total_renovations;
      this.daily_total_value = resp.data.total_value;

      this.daily_cancelation = resp2.data.total_cancelations;
      this.daily_cancelation_value = resp2.data.total_value;

      this.daily_new_subscriptions = resp3.data.total_new_sub;
      this.daily_new_subscriptions_value = resp3.data.total_value;

      const finalDates = this.formatLineChartDates(
        resp.data.dates,
        resp2.data.dates,
        resp3.data.dates,
        future_subs
      );

      let series = [
        {
          name: "Renovações",
          data: resp.data.values,
        },
        {
          name: "Cancelamentos",
          data: resp2.data.values,
        },
        {
          name: "Novas assinaturas",
          data: resp3.data.values,
        }
      ];

      if (this.isFuturePartial) {
        series.push({
          name: "Assinaturas Futuras",
          data: future_values,
        });
      }

      this.yearlyChartOptions = {
        chart: {
          type: "line",
          toolbar: {
            show: true,
          },
        },
        series: series,
        xaxis: {
          categories: finalDates, // ['05/2023', '06/2023']
          title: {
            text: "Dias",
          },
          labels: {
            style: {
              fontSize: "9px",
            },
          },
        },
        yaxis: {
          title: {
            text: "Total",
          },
        },
      };
    },
    getOrigin(value) {
      if (!value) {
        return "Asaas";
      }

      switch (value) {
        case "APP_STORE":
          return "Apple";
        case "PLAY_STORE":
          return "Play Store";
        default:
          return value;
      }
    },
    getPlan(value) {
      if (value.includes("Professor")) {
        return "#226cfc";
      }

      return "#4eba46";
    },
    formatCurrency(value) {
      if (typeof value !== "number") return value;
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    isPartialFuture() {
      return (moment(this.dataFin).isAfter(moment()) && moment(this.dataIni).isSameOrBefore(moment()));
    }
  },
  async mounted() {
    this.getPlanList();
    this.loadFilters();
  },
  computed: {
    totalValue() {
      return this.results.reduce((sum, item) => sum + item.value, 0);
    },
  },
  data() {
    return {
      search: "",
      dataIni: moment().startOf("month").format("YYYY-MM-DD"),
      dataFin: moment().format("YYYY-MM-DD"),
      originFilter: "",
      planFilter: "",
      loading: false,
      disabledButton: false,
      isFuture: false,
      isFuturePartial: false,
      hideFilters: false,
      headers: [
        { text: "Nome", value: "name", width: "20%" },
        { text: "Plano", value: "plan", width: "20%" },
        { text: "Valor", value: "value", width: "20%" },
        { text: "Data", value: "data", width: "20%" },
        { text: "Origem", value: "payment_type", width: "20%" },
      ],
      originFilterOption: [
        {
          id: "ASAAS",
          title: "Asaas",
        },
        {
          id: "PLAY_STORE",
          title: "Play Store",
        },
        {
          id: "APP_STORE",
          title: "Apple",
        },
      ],
      planFilterOption: [],
      results: [],
      daily_total: 0,
      daily_total_value: 0.0,
      daily_cancelation: 0,
      daily_cancelation_value: 0.0,
      daily_new_subscriptions: 0,
      daily_new_subscriptions_value: 0.0,
      future_value: 0.0,
      future_subscriptions: 0.0,
      total: 0,
      total_value: 0.0,
      pieChartAll: { list: [], total: 0.0 },
      pieChartAsaas: { list: [], total: 0.0 },
      pieChartApple: { list: [], total: 0.0 },
      pieChartGoogle: { list: [], total: 0.0 },
      salesByCurrentYear: [
        {
          name: "Vendas",
          data: [],
        },
      ],
      yearlyChartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: true,
          },
        },
        xaxis: {
          categories: [],
        },
        stroke: {
          curve: "smooth",
        },
      },
      chartOptionsRenovation: {
        chart: {
          type: "bar",
          id: "columnChart",
        },
        dataLabels: {
          enabled: false,
        },
        colors: [
          "#4a4a4a", // Aluno mensal
          "#807f7f", // Aluno trimestral
          "#d3cfcf", // Aluno anual
          "#e3dcdc", // Professor mensal
          "#b45830", // Professor trimestral
          "#6e3a2b", // Professor anual
        ],
        legend: {
          position: "top", // Legenda na parte superior
          horizontalAlign: "center",
          markers: {
            radius: 5, // Pílulas redondas
          },
        },
        xaxis: {
          categories: [
            "Aluno mensal",
            "Aluno trimestral",
            "Aluno anual",
            "Professor mensal",
            "Professor trimestral",
            "Professor anual",
          ],
          labels: {
            style: {
              colors: "#333",
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#333",
              fontSize: "12px",
            },
          },
        },
        title: {
          text: "Renovação",
          align: "center",
          style: {
            fontSize: "18px",
            fontWeight: "bold",
          },
        },
      },
      renovationSeries: [
        {
          name: "Renovações",
          data: [80, 90, 70, 100],
        },
      ],
    };
  },
};
</script>
