<template>
    <div>
        <h2 style="margin: 10px 20px">Relatório de Ações dos Usuários</h2>
        <div style="width: 100%; padding: 20px">
            <v-col class="filter" cols="12">
                <v-form>
                    <v-row>
                        <v-col cols="12" md="3">
                            <v-text-field v-model="dataIni" label="Data Inicial" type="date"
                                prepend-icon="mdi-calendar"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="3">
                            <v-text-field v-model="dataFin" label="Data Final" type="date"
                                prepend-icon="mdi-calendar"></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row align="end" justify="end">
                    <v-btn color="warning" :loading="loading" class="btn-margin" depressed :disabled="disabledButton"
                        @click="downloadReport()">
                        Exportar
                    </v-btn>
                    <v-btn color="primary" :loading="loading" class="btn-margin" depressed :disabled="disabledButton"
                        @click="loadFilters()">
                        Filtrar
                    </v-btn>
                    <v-btn color="error" class="btn-margin" :loading="loading" depressed @click="removeFilters()"
                        :disabled="disabledButton">
                        Remover filtros
                    </v-btn>
                </v-row>
            </v-col>
            <v-container fluid class="pa-0">
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Pesquisar" single-line
                            hide-details style="margin-top: 10px; margin-bottom: 10px">
                        </v-text-field>
                        <v-data-table :loading="loading" :headers="headers" :items="results" :items-per-page="10"
                            :search="search" class="elevation-1">
                            <template v-slot:item.created_at="{ item }">
                                {{ item.created_at ?? " " }}
                            </template>
                            <template v-slot:item.status="{ item }">
                                {{ item.status ?? " " }}
                            </template>
                            <template v-slot:item.rca="{ item }">
                                {{ item.rca ?? " " }}
                            </template>
                            <template v-slot:item.email="{ item }">
                                {{ item.email ?? " " }}
                            </template>
                            <template v-slot:item.user="{ item }">
                                {{ item.user ?? " " }}
                            </template>
                            <template v-slot:item.product="{ item }">
                                {{ item.product ?? " " }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<style>
.custom-table th,
.custom-table td {
    padding: 0 120px;
    /* Define o preenchimento personalizado */
}

.footer-table {
    border-top: thin solid rgba(0, 0, 0, 0.12);
    font-size: 0.875rem;
    height: 48px;
    display: flex;
    padding: 10px 15px;

    .custom-td {
        width: 20%;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    .title-footer {
        font-weight: bold;
    }
}

.filter {
    margin-bottom: 15px;
}

.btn-margin {
    margin: 5px;
}

.border {
    border: 1px solid #848484 !important;
}

.card {
    position: relative;
    border: 1px solid #848484 !important;
    height: 380px !important;
}

.renovations-total {
    font-weight: bold;
    font-size: 16px;
}

.renovation-card {
    text-align: center;
}

.card-total {
    position: absolute;
    bottom: 10px;
    left: 25%;
    font-weight: bold;
}

.cursor {
    cursor: pointer;
}

.v-data-table__expanded.v-data-table__expanded__content {
    box-shadow: none !important;
}
</style>
<script>
import moment from "moment";
moment.locale("pt-br");

export default {
    methods: {
        async loadFilters() {
            this.getList();
        },
        async removeFilters() {
            this.dataIni = moment().startOf("month").format("YYYY-MM-DD");
            this.dataFin = moment().format("YYYY-MM-DD");
        },
        async getList() {
            this.loading = true;
            this.disabledButton = true;

            const resp = await this.$api.get(`/actions_api?initial_date=${this.dataIni}&final_date=${this.dataFin}`);

            this.loading = false;
            this.disabledButton = false;
            this.results = resp.data.list;
        },
        async downloadReport() {
            this.loading = true;
            this.disabledButton = true;

            const url = `/actions_api/report?initial_date=${this.dataIni}&final_date=${this.dataFin}`;
            this.$api.get(url).then((response) => {
                this.loading = false;
                this.disabledButton = false;
                let mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";

                const url = mediaType + response.data;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'relatorio_webhook.xlsx'); // Set the filename for the downloaded file
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
                this.loading = false;
                this.disabledButton = true;
                console.error('Error downloading Excel file:', error);
            });
        }
    },
    async mounted() {
        this.loadFilters();
    },
    data() {
        return {
            search: "",
            dataIni: moment().startOf("month").format("YYYY-MM-DD"),
            dataFin: moment().format("YYYY-MM-DD"),
            loading: false,
            disabledButton: false,
            headers: [
                { text: "Data", value: "created_at" },
                { text: "Evento", value: "status" },
                { text: "Valor", value: "rca" },
                { text: "Email", value: "email" },
                { text: "Usuário", value: "user" },
                { text: "Produto", value: "product" },
            ],
            results: [],
        };
    },
};
</script>