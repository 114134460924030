<template>
    <ActionReport />
  </template>
  
  <script>
  import ActionReport from "@/components/Reports/ActionReport.vue";
  export default {
    components: {
        ActionReport,
    },
  };
  </script>
  