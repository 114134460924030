import Vue from "vue";
import VueRouter from "vue-router";
import LoginV2 from "../views/Login-v2.vue";
import Login from "../views/Login.vue";
import ListUsers from "../views/Users/ListUsers.vue";
import EditUser from "../views/Users/EditUser.vue";
import ListClassItem from "../views/ListClassItem.vue";
import CreateClassItem from "../views/CreateClassItem.vue";
import CreateUser from "../views/Users/CreateUser.vue";
import AccountV2 from "../views/Account-v2.vue";
import AccountV2Edit from "../views/Account-v2-edit.vue";
import ManageSubscriptionV2 from "../views/ManageSubscription-v2.vue";
import CouponV2 from "../views/Coupon-v2.vue";
import NewSubscription from "../views/NewSubscription.vue";
import SupportV2 from "../views/Support-v2.vue";
import PaymentMethodsV2 from "../views/PaymentMethods-v2.vue";
import Home from "../views/Home.vue";
import ViewCategories from "../views/Categories/ViewCategories";
import CreateCategory from "../views/Categories/CreateCategory";
import EditCategory from "../views/Categories/EditCategory";
import ListLevels from "../views/Levels/ListLevels";
import AddMediaContent from "../views/ClassItem/AddMediaContent";
import EditClassItem from "../views/ClassItem/EditClassItem";
import CreateLevel from "../views/Levels/CreateLevel";
import EditLevel from "../views/Levels/EditLevel";
import PasswordRecovery from "../views/PasswordRecovery";
import CreateAccount from "../views/Users/CreateAccount";
import InitialPage from "../views/InitialPage";
import ListPayments from "../views/Users/ListPayments.vue";
import ChangePassword from "../views/Users/ChangePassword.vue";
import Comments from "../views/ClassItem/Comments";
import AddPayment from "../views/Users/AddPayment.vue";
import AddMediaCategory from "../views/Categories/AddMediaCategory";
import Config from "../views/Config.vue";
// import Payment from "../views/Payment/payment.vue";
import CodeTv from "../views/Tv/CodeTv.vue";
import CreateAccountV2 from "../views/CreateAccount-v2.vue";
import ChoosePlan from "../views/ChoosePlan.vue";
import CouponSubscription from "../views/CouponSubscription.vue";
import SubscriptionsReport from "../views/Reports/SubscriptionsReport.vue";
import ActionsReport from "../views/Reports/ActionsReport.vue";

import TermsOfUse from "../views/TermsOfUse";

import EditPlan from "../views/Plans/EditPlan";
import CreatePlan from "../views/Plans/CreatePlan";
import ListPlan from "../views/Plans/ListPlans";

import ListCoupons from "../views/Coupons/ListCoupons.vue";
import ReportCupom from "../views/Coupons/ReportCoupon.vue";
import EditCoupons from "../views/Coupons/EditCoupon.vue";
import CreateCoupons from "../views/Coupons/CreateCoupon.vue";
import CheckoutVue from "../views/Checkout.vue";
import CreateSubcategory from "../views/Subcategories/CreateSubcategory.vue";
import EditSubcategory from "../views/Subcategories/EditSubcategory.vue";

Vue.use(VueRouter);

const TITLE = "WeFlow";
const routes = [
  {
    path: "/",
    name: "Home",
    component: AccountV2,
    meta: { requiresAuth: true, roles: ["student", "teacher"] },
  },
  {
    path: "/painel",
    name: "Painel",
    component: Home,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/manageSubscription-v2",
    name: "ManageSubscriptionV2",
    component: ManageSubscriptionV2,
    meta: { requiresAuth: true, roles: ["student", "teacher"] },
  },
  {
    path: "/couponSubscription",
    name: "CouponSubscription",
    component: CouponSubscription,
    meta: { requiresAuth: true, roles: ["student", "teacher"] },
  },
  {
    path: "/account-v2",
    name: "AccountV2",
    component: AccountV2,
    meta: { requiresAuth: true, roles: ["student", "teacher"] },
  },
  {
    path: "/account-v2-edit",
    name: "AccountV2Edit",
    component: AccountV2Edit,
    meta: { requiresAuth: true, roles: ["student", "teacher"] },
  },
  {
    path: "/support-v2",
    name: "SupportV2",
    component: SupportV2,
    meta: { requiresAuth: true, roles: ["student", "teacher"] },
  },
  {
    path: "/coupon-v2",
    name: "CouponV2",
    component: CouponV2,
    meta: { requiresAuth: true, roles: ["student", "teacher"] },
  },
  {
    path: "/newSubscription/:idPlano",
    name: "NewSubscription",
    component: NewSubscription,
    meta: { requiresAuth: true, roles: ["student", "teacher"] },
  },
  {
    path: "/payment-methods-v2",
    name: "PaymentMethodsV2",
    component: PaymentMethodsV2,
    meta: { requiresAuth: true, roles: ["student", "teacher"] },
  },
  {
    path: "/comprar",
    name: "Comprar",
    component: ChoosePlan,
  },
  {
    path: "/CreateAccount",
    name: "CreateAccount",
    component: CreateAccount,
  },
  {
    path: "/initialPage",
    name: "InitialPage",
    component: InitialPage,
  },
  {
    path: "/editUser/:id/listPayments",
    name: "ListPayments",
    component: ListPayments,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/editUser/:id/changePassword",
    name: "ChangePassword",
    component: ChangePassword,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/editUser/:id/addPayment",
    name: "AddPayment",
    component: AddPayment,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/report-coupons",
    name: "ReportCoupons",
    component: ReportCupom,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/subscription-report",
    name: "SubscriptionsReport",
    component: SubscriptionsReport,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/actions-report",
    name: "ActionsReport",
    component: ActionsReport,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/coupons",
    name: "ListCoupons",
    component: ListCoupons,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/coupons/:id",
    name: "EditCoupons",
    component: EditCoupons,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/createCoupons",
    name: "CreateCoupons",
    component: CreateCoupons,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/addMediaCategory/:id",
    name: "AddMediaCategory",
    component: AddMediaCategory,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/createSubcategory/:category_id",
    name: "CreateSubcategory",
    component: CreateSubcategory,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/editSubcategory/:subcategory_id",
    name: "EditSubcategory",
    component: EditSubcategory,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  // {
  //   path: "/payments",
  //   name: "Payment",
  //   component: Payment,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login-user",
    name: "LoginUser",
    component: LoginV2,
  },
  {
    path: "/listPlans",
    name: "ListPlan",
    component: ListPlan,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/configs",
    name: "Config",
    component: Config,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/alterar-senha",
    name: "PasswordRecovery",
    component: PasswordRecovery,
  },
  {
    path: "/CreatePlan",
    name: "CreatePlan",
    component: CreatePlan,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/editPlan/:id",
    name: "EditPlan",
    component: EditPlan,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/createLevel",
    name: "CreateLevel",
    component: CreateLevel,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/editLevel/:id",
    name: "EditLevel",
    component: EditLevel,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/editClassItem/:id",
    name: "EditClassItem",
    component: EditClassItem,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/editCategory/:id",
    name: "EditCategory",
    component: EditCategory,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/createAccount-v2/:idPlano",
    name: "CreateAccount-v2",
    component: CreateAccountV2,
    meta: { requiresAuth: false, roles: ["guest"] },
  },
  {
    path: "/addMediaContent/:id",
    name: "AddMedia",
    component: AddMediaContent,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/categories",
    name: "Categories",
    component: ViewCategories,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/createCategory",
    name: "CreateCategory",
    component: CreateCategory,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/levels",
    name: "Levels",
    component: ListLevels,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/editUser/:id",
    name: "EditUser",
    component: EditUser,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/choosePlan",
    name: "ChoosePlan",
    component: ChoosePlan,
    meta: { requiresAuth: false, roles: ["guest"] },
  },
  {
    path: "/success/:plan",
    name: "Success",
    component: CheckoutVue,
    meta: { requiresAuth: false, roles: ["guest"] },
  },
  {
    path: "/createClassItem",
    name: "CreateClassItem",
    component: CreateClassItem,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/classItems",
    name: "ClassItem",
    component: ListClassItem,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/classItems/:id/comments",
    name: "Comments",
    component: Comments,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/termos-de-uso",
    name: "TermsOfUser",
    component: TermsOfUse,
  },
  {
    path: "/createUser",
    name: "CreateUser",
    component: CreateUser,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/users",
    name: "ListUsers",
    component: ListUsers,
    meta: { requiresAuth: true, roles: ["admin"] },
  },
  {
    path: "/tv",
    name: "CodeTv",
    component: CodeTv,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  document.title = TITLE;

  const role = isAuthenticated?.type || "guest";

  console.log("IS AUTHENTICATED: ", isAuthenticated);

  if (to.meta.requiresAuth) {
    if (!isAuthenticated) {
      if (to.meta.roles.includes("student")) next({ name: "LoginUser" });
      else if (to.meta.roles.includes("guest")) next();
      else next({ name: "Login" });
    } else if (to.meta.roles && !to.meta.roles.includes(role)) {
      switch (role) {
        case "admin":
          next({ path: "/login" });
          break;
        default:
          next({ name: "LoginUser" });
          break;
      }
    } else {
      next();
    }
  } else {
    if (isAuthenticated && to.meta.roles && to.meta.roles.includes("guest")) {
      next({ name: "Home" });
    } else {
      next();
    }
  }
});

export default router;
